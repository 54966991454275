import React from "react"
import { Box, Button, Text } from "src/styled"
import { Checkbox, Input, Loading } from "src/components/common"
import { ButtonContainer, Form } from "./components"
import { useSelector } from "react-redux"

const SignUpForm2 = ({
    isActive,
    state,
    errors,
    onChange,
    handleToggle,
    onSubmit,
    onPrevious,
}) => {
    const { isAuthenticating, signUpError } = useSelector(state => state.user)
    return (
        <Box>
            <Form isActive={isActive}>
                <Checkbox
                    checked={state.nipIsActive}
                    onToggle={handleToggle}
                    label={"Czy chcesz byc zarejestrowany jako firma?"}
                />

                <Input
                    name="name"
                    error={errors.name}
                    value={state.name}
                    onChange={onChange}
                    label={"Nazwa"}
                    placeholder={"Twoje imię i nazwisko lub nazwa firmy"}
                />

                <Input
                    name="nip"
                    value={state.nip}
                    error={errors.nip}
                    onChange={onChange}
                    label={"NIP"}
                    disabled={!state.nipIsActive}
                    placeholder={"Wpisz swój NIP"}
                />

                <Input
                    name="address"
                    value={state.address}
                    error={errors.address}
                    onChange={onChange}
                    label={"Adres"}
                    placeholder={"Wpisz swój podstawowy adres do dostaw."}
                />
                {signUpError && <Text color="danger"> {signUpError}</Text>}

                <ButtonContainer>
                    <Button
                        variant={"primary-reverse-wide"}
                        onClick={onPrevious}
                    >
                        Wstecz
                    </Button>

                    <Button
                        disabled={isAuthenticating}
                        variant={"primary-wide"}
                        onClick={onSubmit}
                    >
                        {isAuthenticating ? <Loading color="white" /> : "Dalej"}
                    </Button>
                </ButtonContainer>
            </Form>
        </Box>
    )
}

export default SignUpForm2
