import Joi from "joi"

const form1Schema = Joi.object()
    .keys({
        email: Joi.string()
            .email({ minDomainSegments: 2, tlds: { deny: [] } })
            .required()
            .messages({
                "string.empty": "Do uzupełnienia",
                "any.required": "Do uzupełnienia",
                "string.email": "Podaj właściwy mail",
            }),
        phoneNumber: Joi.string()
            .required()
            .messages({
                "string.empty": "Do uzupełnienia",
                "any.required": "Do uzupełnienia",
            }),
        password: Joi.string()
            .min(8)
            .required()
            .messages({
                "string.empty": "Do uzupełnienia",
                "string.min": "Hasło musi posiadać minimum 8 znaków",
                "any.required": "Do uzupełnienia",
                "any.only": "Hasła nie są zgodne",
            }),
        repeatPassword: Joi.string()
            .required()
            .valid(Joi.ref("password"))
            .messages({
                "any.required": "Do uzupełnienia",
                "string.empty": "Do uzupełnienia",
                "any.only": "Hasła nie są zgodne",
            }),
    })
    .with("password", "repeatPassword")
    .unknown(true)

export const validateForm1 = state =>
    form1Schema.validate(state, { abortEarly: false })

const form2Schema = Joi.object()
    .keys({
        name: Joi.string()
            .required()
            .messages({
                "string.empty": "Do uzupełnienia",
                "any.required": "Do uzupełnienia",
            }),
        address: Joi.string()
            .required()
            .messages({
                "string.empty": "Do uzupełnienia",
                "any.required": "Do uzupełnienia",
            }),
        nip: Joi.when("nipIsActive", {
            is: true,
            then: Joi.string()
                .required()
                .messages({
                    "string.empty": "Do uzupełnienia",
                    "any.required": "Do uzupełnienia",
                }),
        }),
    })
    .unknown(true)

export const validateForm2 = state =>
    form2Schema.validate(state, { abortEarly: false })

const signInSchema = Joi.object({
    email: Joi.string()
        .email({ minDomainSegments: 2, tlds: { deny: [] } })
        .required()
        .messages({
            "string.empty": "Do uzupełnienia",
            "any.required": "Do uzupełnienia",
            "string.email": "Podaj właściwy mail",
        }),

    password: Joi.string()
        .min(8)
        .required()
        .messages({
            "string.empty": "Do uzupełnienia",
            "string.min": "Hasło musi posiadać minimum 8 znaków",
            "any.required": "Do uzupełnienia",
        }),
})

export const validateSignIn = state =>
    signInSchema.validate(state, { abortEarly: false })
