import React, { useState } from "react"

export default function useForm(initState = {}) {
    const [state, setState] = useState(initState)
    const [errors, setErrors] = useState({})

    const handleChange = e => {
        e.persist()
        if (
            e.target.name === "phoneNumber" &&
            !/^[0-9+]*$/.test(e.target.value)
        ) {
            setErrors(prevErrors => ({
                ...prevErrors,
                phoneNumber:
                    "Numer telefonu może zawierać tylko cyfry i znak +.",
            }))
            return
        } else
            setErrors(prevErrors => ({
                ...prevErrors,
                phoneNumber: undefined,
            }))
        setState(prevState => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    return { state, handleChange, setState, errors }
}
