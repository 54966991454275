import styled from "styled-components"
import { Flex, Box, Image, Container, Header, Text } from "src/styled"
import { devices } from "src/theme/device"
import theme from "src/theme"

export const Form = styled.div`
    display: flex;
    width: 100%;
    flex: 1;
    position: relative;
    height: fit-content;
    transition: 0.5s ease-in-out;
    flex-direction: column;
    transform: ${({ position }) => position};
    display: ${({ isActive }) => (isActive ? "flex" : "none")};
`

export const StyledContainer = styled(Container)`
    min-height: calc(100vh - 70px);
    display: flex;
    padding: 20px;
    ${devices.tablet} {
        width: 100%;
        padding: 10px;
    }
`

export const StyledImage = styled(Image)`
    border-radius: 10px;
    width: 90%;
    margin: auto;
    filter: drop-shadow(100px 100px 3px #e2e0e0);
`

export const Wrapper = styled(Flex)`
    margin: auto;
    flex-direction: row;
    flex: 1;
    height: 100%;
`

//Form
export const FormContainer = styled(Flex)`
    flex: 1;
    flex-direction: column;
    height: fit-content;
    position: relative;
`
export const ErrorMessage = styled(Text)`
    margin: 20px 0 0 0;
    color: ${theme.colors.danger};
`

export const Title = styled(Header)`
    font-size: 48px;
    ${devices.tablet} {
        font-size: 40px;
    }
`

export const TopLeftShadow = styled(Box)`
    height: 300px;
    width: 300px;
    border-radius: 5px;
    position: absolute;
    top: 10px;
    left: 0;
    background: ${theme.colors.lightGray};
`

export const ImageWrapper = styled(Flex)`
    flex: 1;
    background: white;

    ${devices.laptop} {
        display: none;
    }
`

export const ButtonContainer = styled(Flex)`
    flex: 1;
    margin-top: 30px;
    justify-content: space-between;

    ${devices.tablet} {
        flex-direction: column;
    }
`

export const ForgotPassword = styled(Text)`
    color: ${theme.colors.primary};
    cursor: pointer;
`

export const StepsText = styled(Text)`
    font-size: 24px;
    color: ${theme.colors.secondary};
    ${devices.tablet} {
        font-size: 20px;
    }
`
