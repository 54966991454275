//@ts-check
import React, { useState, useEffect } from "react"
import { navigate } from "@reach/router"
import { Loading } from "src/components/common"
import { getCurrentAuthenticatedUser } from "src/api/rest/auth"

/**
 * @param {Component} Component to load after checking auth status
 * @param {Boolean} boolean to load with or withoout authentication
 */

const withAuth = (Component, withAuthentication = true, redirectLink = "/") => {
    function AuthenticationPage(props) {
        const [isLoading, setIsLoading] = useState(true)

        useEffect(() => {
            const checkAuth = async () => {
                try {
                    await getCurrentAuthenticatedUser()
                    if (withAuthentication) setIsLoading(false)
                    else navigate(redirectLink)
                } catch (er) {
                    if (withAuthentication) navigate(redirectLink)
                    else setIsLoading(false)
                }
            }
            checkAuth()
        }, [])

        return isLoading ? <Loading /> : <Component {...props} />
    }

    return AuthenticationPage
}
export default withAuth
