import React from "react"
import { Button, Box } from "src/styled"
import { Input } from "src/components/common"
import { ButtonContainer, Form } from "./components"

export default function SignUpForm1({
    isActive,
    state,
    errors,
    onChange,
    onNext,
}) {
    return (
        <Box>
            <Form isActive={isActive}>
                <Input
                    name="phoneNumber"
                    onChange={onChange}
                    value={state.phoneNumber}
                    error={errors.phoneNumber}
                    label={"Numer telefonu*"}
                    placeholder={"Tu zadzwonimy w celu potwierdzenia godziny"}
                />

                <Input
                    name="email"
                    onChange={onChange}
                    value={state.email}
                    error={errors.email}
                    label={"Adres email*"}
                    placeholder={"Adres do logowania oraz płatności online"}
                />

                <Input
                    name="password"
                    type="password"
                    onChange={onChange}
                    value={state.password}
                    error={errors.password}
                    label={"Hasło*"}
                    placeholder={"Minimum 8 znaków w tym jedna duża litera"}
                />

                <Input
                    type="password"
                    name="repeatPassword"
                    onChange={onChange}
                    value={state.repeatPassword}
                    error={errors.repeatPassword}
                    label={"Powtórz hasło*"}
                    placeholder={"Wpisz ponownie hasło."}
                />
                <ButtonContainer>
                    <Button variant={"primary-wide"} onClick={onNext}>
                        Dalej
                    </Button>
                </ButtonContainer>
            </Form>
        </Box>
    )
}
